<template>
    <div class="qcc-progress">
        <h5>医键通任务排序</h5>
        <div class="search-box">
            <el-input
                placeholder="搜索"
                v-model="keyword">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div>
        <!-- 任务列表 -->
        <div class="task-lis" :style="{ overflow: 'auto' }">
            <draggable 
                :list="allTask"
                :delay="150"
                :animation="200"
                :forceFallback="true"
                dragClass="dragClass"
                ghostClass="ghostClass"
                chosenClass="chosenClass"
                tag="div"
                @end="draggableEnd"
                
            >
                <transition-group type="transition" name="flip-list">
                    <div 
                        :class="['task-item']" 
                        v-for="(v,i) in allTask" 
                        :key="'tasks'+i"
                        @click="showById(v.id)"
                    >
                        <div class="task-item-main">
                            <div class="task-item-left">
                                <h6>{{v.title}}</h6>
                                <span>{{getDateFormat(v.ctime)}}</span>
                            </div>
                            
                            <div class="task-item-right">
                                <span>{{i+1}}</span>
                            </div>
                        </div>
                        <!-- <p>内容内容内容内容内容内容内容内容内容内容内容neirong容内容内容内容neirong</p> -->
                    </div>
                </transition-group>
            </draggable>
        </div>
        <!-- 未完成和未完成 -->
        <div class="tab-footer">
            <span :class="['tab',currTab === '1' ? 'curr-tab' : '']" @click="handleTab('1')">未完成</span>
            <span :class="['tab',currTab === '2' ? 'curr-tab' : '']" @click="handleTab('2')">已完成</span>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  postTaskLisAll
} from '@/service/help';
import { dateFormat } from '@/utils/utils'
export default {
    data(){
        return{
            currTab:'1', // 1未完成 2已完成
            keyword:'',
            allTask:[],
            // 拖拽
            timeOutEvent: null,
            isDisabledDraggable:true,
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                handle:".mover",
                filter:".unmover"
            }
        }
    },
    components:{
        draggable
    },
    created(){
        // .addEventListener('contextmenu', function(e){
        //     e.preventDefault();
        // });
        this.getAllTask()
    },
    methods:{
        showById(id) {
            console.log('id', id);
            this.$emit('goTo', 'taskDetail', {
                data: { id },
                handler: that => {
                    that.traId.push(id);
                },
            });
        },
        getDateFormat(date){
           return dateFormat('YY年MM月DD日 hh:mm', new Date(date))
        },
        getAllTask() {
            //查看所有任务
            postTaskLisAll({
                keyword: this.keyword
            }).then(res => {
                let data = res.data.data;
                this.allTask = data;
            });
        },
        // 结束拖拽
        draggableEnd(event) {
            // event.item  拖拽的本身
            // event.to      拖拽的目标列表
            // event.from    拖拽之前的列表
            // event.oldIndex    拖拽前的位置
            // event.newIndex    拖拽后的位置
            console.log(event,'结束拖拽')
        },
        handleTab(tab){
            this.currTab = tab
        }
    }
}
</script>


<style lang="less" scoped>
.qcc-progress{
    height: 100%;
    background: linear-gradient( 180deg, #51A8FF 0%,  rgba(40,147,255,0) 40%);
    display: flex;
    flex-direction: column;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    h5{
        font-size: 18px;
        color: #FFFFFF;
        padding: 12px 0;
        text-align: center;
    }
    .search-box{
        width: 90%;
        margin:  0 auto;
        margin-bottom: 10px;
    }
    .task-lis{
        width: 90%;
        flex: 1;
        margin: 0 auto;
        // overflow-y: auto;
        .task-item{
            height: 143px;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.04);
            border-radius: 10px 10px 10px 10px;
            box-sizing: border-box;
            padding: 12px 10px;
            margin-bottom: 10px;
           
            .task-item-main{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                border-bottom: 1px solid #DBE3F1;
            }
            
            .task-item-left{
                margin-right: 10px;
                h6{
                    font-weight: bold;
                    font-size: 18px;
                    color: #334681;
                    padding-bottom: 6px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 显示3行 */
                    overflow: hidden;
                    text-overflow: ellipsis; /* 可选，用来在结尾显示省略号 */
                    max-height: 3em; /* 3行行高的大约高度，可以调整为其他值 */
                    line-height: 1.5em; /* 行高 */
                }
                span{
                    font-size: 16px;
                    color: #8590B3;
                }
            }
            .task-item-right{
                span{
                    display: block;
                    width: 80px;
                    height: 80px;
                    background: #13BFFF;
                    border-radius: 6px 6px 6px 6px;
                    font-size: 32px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                    font-weight: bold;
                }
            }
            p{
                font-size: 16px;
                color: #8590B3;
                padding-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .tab-footer{
        width: 100%;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px -2px 20px 0px rgba(60,156,255,0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 67px;
        .tab{
            font-size: 18px;
            color: #909193;
            line-height: 24px;
            &.curr-tab{
                color: #13BFFF;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: #13BFFF;
                    border-radius: 13px 13px 13px 13px;
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                }
            }
        }
    }
}


.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


/*定义要拖拽元素的样式*/
 .ghostClass {
  background-color: blue !important;
}

.chosenClass {
  background-color: transparent !important;
  opacity: 1 !important;
}

.dragClass {
  background-color: rgb(89, 177, 236) !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none !important;
  background-image: none !important;
}

</style>

