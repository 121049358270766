<template>
  <div class="progress-mobile">
    <template v-if="!isSelecting">
      <Common @handleCommon="handleCommon" :topStyle="topStyle" :mainStyle="mainStyle">
        <nav :style="navStyle">
          <ul class="flex-w tasktype-ul">
            <li
              v-for="item in funList"
              :key="item.key"
              @click="handleNav(item)"
              :class="{ aliveNav: aliveKey === item.key }"
            >
              <div :class="['ico', item.ico]" />
              <span>{{ item.str.substring(0, 2) }}</span>
            </li>
          </ul>
          <div class="flex-w screen-search">
            <input
              class="search"
              placeholder="搜索"
              v-model="keyword"
              @input="debounceGetData"
              @keyup.enter="getData"
            />
            <div :class="['screen', openScreen ? 'blue-ico' : 'grey-ico']" @click="handleOpenScreen"></div>
          </div>
          <!-- <transition> -->
          <div class="muit-tab">
            <template v-for="(ti, index) in tabData">
              <div class="tab-title">{{ ti.title }}</div>
              <div class="flex-w tab-ul">
                <div
                  :class="{ tab: true, 'active-tab': ti.curr.includes(tab) }"
                  v-for="tab in ti.tabs"
                  :key="tab"
                  @click="handleTabClick(index, tab)"
                >
                  {{ tab }}
                </div>
              </div>
            </template>
          </div>
          <!-- </transition> -->
        </nav>
        <template v-slot:headers>
          <div style="margin-left: 4.1rem; margin-right: 0"></div>
          <div style="margin-left: 4.1rem; margin-right: auto"></div>
          <div class="title">任务</div>
          <div class="img img-clock" />
          <div class="el-icon-circle-plus-outline img img-add" @click="handleAddTask"></div>
        </template>
      </Common>

      <div class="flex-w result">
        <ul class="one-nav" v-show="aliveKey == 'byOne'">
          <li
            :class="{ 'one-li': true, 'flex-w': true, 'one-active': activeOne == index }"
            v-for="(one, index) in byOne"
            :key="index"
            @click="handleClickOneNav(index)"
          >
            {{ one.name }}
          </li>
        </ul>
        <ul class="task-ul">
          <li v-for="item in taskLis" :key="item.id" @click="showById(item.id)">
            <div class="flex-w box1">
              <div
                :style="{ backgroundColor: xIco[item.status].bgColor, color: xIco[item.status].color }"
                class="flex-w status-ico"
              >
                <img :src="xIco[item.status].ico" alt />
                <span>{{ xIco[item.status].str }}</span>
              </div>
              <div class="sn">{{ item.sn }}</div>
              <img
                class="portrait"
                :src="item.avatar"
                :alt="lastName(item.name)"
                v-show="aliveKey != 'byOne'"
                v-if="item.name !== ''"
              />
            </div>
            <div class="title-show">{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </template>
    <formContainer
      v-else
      :para="formPara"
      @addtask="addtask"
      @back="handleBack"
      @handleChange="handleChange"
      @handleNext="handleNext"
    ></formContainer>
  </div>
</template>
<script>
// import Member from '@/components/task/progress/member';
import formContainer from '@/components/mobile/formContainer/index';
import {
  postTaskLisMyself,
  postTaskDel,
  postTaskLisWho,
  postTaskChargeMod,
  postTaskLisAll,
  postTaskNew,
} from '@/service/help';
import { memberMixin, xIco, tasklevel as level, taskType } from '@/common/js';
import { lastName, _debounce } from '@/utils/utils';
export default {
  mixins: [memberMixin],
  props: ['para'],
  data() {
    return {
      // taskType,
      xIco,
      aliveKey: 'allTask',
      mainStyle: 'progress-main',
      openScreen: false,
      tabData: [
        { title: '状态', tabs: xIco.map(v => v.str), curr: [] },
        { title: '紧急程度', tabs: level.map(v => v.value), curr: [] },
      ],
      keyword: '',
      allTask: [],
      byOne: [],
      activeOne: 0,
      isSelecting: false,
      newTaskFrom: {
        title: '新建任务',
        type: 'addtask',
        value: {},
        id: 'newTask',
        handleOk: true,
        okText: '完成',
      },
      formPara: {},
    };
  },
  mounted() {
    console.log(this.traId, this.taskId);
    this.$nextTick(() => {
      if (!!this.taskId) {
        this.showById(this.taskId);
      }
    });
    this.getData();
  },
  methods: {
    addtask(data) {
      let { charge_id, title, level, join_id, type } = data;
      // dadtask_id
      postTaskNew({ charge_id, title, level: level.toString(), join_id: join_id.map(v => v.id.toString()), type }).then(
        () => {
          this.getData();
          this.isSelecting = false;
        },
      );
      console.log(data);
    },
    handleChange({ data, type }) {
      console.log('拿到了', data, type);
      this.handleAddTask({ data, type });
    },
    handleBack() {
      if (this.formPara.type != 'addtask') {
        this.handleAddTask();
      } else {
        this.newTaskFrom.value = {};
        this.$set(this, 'formPara', {});
        this.isSelecting = false;
      }
    },
    handleNext(data) {
      this.$set(this, 'formPara', data);
    },
    handleAddTask(value = '') {
      //添加任务
      if (value != '') {
        //合并处理value
        console.log(value, this.newTaskFrom.value);
        if (value.type == 'addtask') {
          this.newTaskFrom.value['title'] = value.data.title;
        } else {
          this.newTaskFrom.value[value.type] = value.data;
        }
      }
      this.$set(this, 'formPara', this.newTaskFrom);
      this.isSelecting = true;
    },
    debounceGetData() {
      //防抖获取数据
      _debounce(this.getData, 500)();
    },
    showById(id) {
      console.log('id', id);
      this.$emit('goTo', 'taskDetail', {
        data: { id },
        handler: that => {
          that.traId.push(id);
        },
      });
    },
    handleCommon() {
      //点击到了屏罩，关闭弹窗
      this.openScreen = false;
    },
    getData() {
      //获取数据
      if (this.aliveKey === 'allTask') {
        this.getAllTask();
      } else if (this.aliveKey === 'byOne') {
        this.getTaskByOne();
      } else if (this.aliveKey === 'myTask') {
        this.getTaskMy();
      }
    },
    getAllTask() {
      //查看所有任务
      postTaskLisAll({
        keyword: this.keyword,
        level: this.level,
        status: this.status,
        join_id: [],
        finish_date: '',
      }).then(res => {
        let data = res.data.data;
        this.allTask = data;
      });
    },
    getTaskMy() {
      //查看我的任务
      postTaskLisMyself({
        keyword: this.keyword,
        level: this.level,
        status: this.status,
        join_id: [],
        finish_date: '',
      }).then(res => {
        let data = res.data.data;
        this.allTask = data;
      });
    },
    getTaskByOne() {
      //查看个人任务
      postTaskLisWho({
        keyword: this.keyword,
        level: this.level,
        status: this.status,
        join_id: [],
        finish_date: '',
      }).then(res => {
        let data = res.data.data;
        console.log(data);
        this.byOne = data;
      });
    },
    handleNav(item) {
      //顶部nav
      let key = item.key;
      this.aliveKey = key;
      this.getData();
    },
    handleClickOneNav(index) {
      // this.oneQuery = this.byOne[index].query
      this.activeOne = index;
    },
    handleOpenScreen() {
      //展开筛选面板
      this.openScreen = !this.openScreen;
    },
    handleTabClick(index, tab) {
      //标签点击
      let targetArr = this.tabData[index].curr;
      if (targetArr.includes(tab)) {
        targetArr = targetArr.filter(v => v != tab);
      } else {
        targetArr.push(tab);
      }
      this.$set(this.tabData[index], 'curr', targetArr);
      this.getData();
    },
    lastName(name) {
      return lastName(name);
    },
  },
  computed: {
    taskId() {
      return (this.para && this.para.id) || 0;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    funList() {
      let arr = taskType;
      // if (this.userInfo.level != '0') {
      //   arr = arr.slice(0, 1).concat(arr.slice(2, 4));
      // }
      arr = arr.slice(0, 1).concat(arr.slice(2, 4));
      return arr;
    },
    navStyle() {
      return 'border-radius:1rem';
    },
    topStyle() {
      return this.openScreen ? 'cover progress-top' : 'progress-top progress-auto-height';
    },
    status() {
      let status = [];
      let statusMap = {};
      xIco.forEach((v, i) => {
        statusMap[v.str] = i;
      });
      this.tabData[0].curr.forEach(s => {
        status.push(statusMap[s]);
      });
      return status;
    },
    level() {
      let levels = [];
      let levelMap = {};
      level.forEach((v, i) => {
        levelMap[v.value] = i;
      });
      this.tabData[1].curr.forEach(l => {
        levels.push(levelMap[l]);
      });
      return levels;
    },
    oneQuery() {
      let arr = this.byOne[this.activeOne].query || [];
      return arr;
    },
    taskLis() {
      if (this.aliveKey == 'byOne') {
        return this.oneQuery;
      } else {
        return this.allTask;
      }
    },
  },
  watch: {
    isSelecting: {
      handler(val) {
        this.switchFoot(!val);
      },
      immediate: true,
    },
  },
  components: {
    formContainer,
  },
};
</script>
<style lang="less">
@import '~@/assets/style/mobile/progress.less';
</style>
