<template>
  <component :para="para" :is="componentId" @goTo="goTo"></component>
</template>

<script>
import taskDetail from '@/components/mobile/progress/taskDetail.vue';
import taskMain from '@/components/mobile/progress/index.vue';
import pccProgress from '@/components/mobile/PccProgress.vue'
export default {
  data() {
    return {
      componentId: process.env.VUE_APP_CONFIG === 'qcc' ? 'pccProgress' : 'taskMain',
      para: null,
      traId: [],
    };
  },
  components: {
    taskDetail,
    taskMain,
    pccProgress
  },
  methods: {
    goTo(id, { data, handler, callback } = {}) {
      this.componentId = '';
      this.$nextTick(() => {
        console.log(id);
        this.$set(this, 'para', data);
        this.componentId = id;
        handler && handler(this);
      });
    },
  },
  watch: {
    componentId: {
      handler(newVal) {
        if (newVal == 'taskMain') {
          this.switchFoot(true);
        } else {
          this.switchFoot(false);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
